/* eslint-disable class-methods-use-this */
import {
  $applyNodeReplacement,
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  EditorConfig,
  LexicalNode,
  SerializedTextNode,
  Spread,
  TextNode,
} from 'lexical';

export type MentionType = 'mention' | 'share' | 'assistant';

export function isMentionType(type: string | null | undefined): type is MentionType {
  return type === 'mention' || type === 'share' || type === 'assistant';
}

export type SerializedMentionNode = Spread<
  {
    reference: string;
    displayName: string;
    mentionType: MentionType;
  },
  SerializedTextNode
>;

function convertMentionElement(domNode: HTMLElement): DOMConversionOutput | null {
  const { textContent } = domNode;
  const reference = domNode.getAttribute('data-mention-reference');
  const mentionType = domNode.getAttribute('data-mention-type');

  if (textContent !== null && reference !== null && isMentionType(mentionType)) {
    // eslint-disable-next-line no-use-before-define
    const node = $createMentionNode(reference, textContent, mentionType);
    return {
      node,
    };
  }

  return null;
}

export class MentionNode extends TextNode {
  __reference: string;

  __displayName: string;

  __mentionType: MentionType;

  static getType(): string {
    return 'mention';
  }

  static clone(node: MentionNode): MentionNode {
    return new MentionNode(
      node.__reference,
      node.__displayName,
      node.__mentionType,
      node.__text,
      node.__key,
    );
  }

  static importJSON(serializedNode: SerializedMentionNode): MentionNode {
    // eslint-disable-next-line no-use-before-define
    const node = $createMentionNode(
      serializedNode.reference,
      serializedNode.displayName,
      serializedNode.mentionType,
    );
    node.setTextContent(serializedNode.text);
    node.setFormat(serializedNode.format);
    node.setDetail(serializedNode.detail);
    node.setMode(serializedNode.mode);
    node.setStyle(serializedNode.style);
    return node;
  }

  static importDOM(): DOMConversionMap | null {
    return {
      span: (dom: HTMLElement) => {
        if (!dom.hasAttribute('data-mention')) {
          return null;
        }
        return {
          conversion: convertMentionElement,
          priority: 1,
        };
      },
    };
  }

  constructor(
    reference: string,
    displayName: string,
    mentionType: MentionType,
    text?: string,
    key?: string,
  ) {
    super(text ?? displayName, key);
    this.__reference = reference;
    this.__displayName = displayName;
    this.__mentionType = mentionType;
  }

  getReference(): string {
    const self = this.getLatest();
    return self.__reference;
  }

  getDisplayName(): string {
    const self = this.getLatest();
    return self.__displayName;
  }

  getMentionType(): MentionType {
    const self = this.getLatest();
    return self.__mentionType;
  }

  isShare(): boolean {
    return this.getMentionType() === 'share';
  }

  isMention(): boolean {
    return this.getMentionType() === 'mention';
  }

  isAssistant(): boolean {
    return this.getMentionType() === 'assistant';
  }

  exportJSON(): SerializedMentionNode {
    return {
      ...super.exportJSON(),
      reference: this.__reference,
      displayName: this.__displayName,
      mentionType: this.__mentionType,
      type: MentionNode.getType(),
      version: 1,
    };
  }

  createDOM(config: EditorConfig): HTMLElement {
    const dom = super.createDOM(config);
    dom.className = config.theme.mention ? config.theme.mention : 'bg-blue-light';
    return dom;
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement('span');
    element.setAttribute('data-mention', 'true');
    element.setAttribute('data-mention-reference', this.__reference);
    element.setAttribute('data-mention-type', this.__mentionType);
    element.textContent = this.__text;
    return { element };
  }

  isTextEntity(): boolean {
    return true;
  }

  canInsertTextBefore(): boolean {
    return false;
  }

  canInsertTextAfter(): boolean {
    return false;
  }
}

export function $createMentionNode(
  reference: string,
  displayName: string,
  mentionType: MentionType,
): MentionNode {
  const mentionNode = new MentionNode(reference, displayName, mentionType);
  mentionNode.setMode('segmented').toggleDirectionless();
  return $applyNodeReplacement(mentionNode);
}

export function $isMentionNode(node: LexicalNode | null | undefined): node is MentionNode {
  return node instanceof MentionNode;
}
