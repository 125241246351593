import { Outlet } from 'react-router';
import { useContactsActions } from './hooks/useContactsActions';
import { BaseLayout } from '../../layout/pages/BaseLayout';
import ContactAddContactButton from './ContactAddContactButton';
import useCreateBreadcrumbItems from '../../layout/navigation/BreadcrumbNavigator/hooks/useCreateBreadcrumbItems';

export function ContactListLayout() {
  const { breadcrumbItems } = useCreateBreadcrumbItems('contacts');
  const actions = useContactsActions();

  return (
    <BaseLayout
      title="Contacts"
      headerActions={actions}
      breadcrumbItems={breadcrumbItems}
      contentAfterHeader={[
        {
          id: 'addContactButton',
          content: <ContactAddContactButton />,
        },
      ]}
    >
      <Outlet />
    </BaseLayout>
  );
}
