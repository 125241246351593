import { Undo2 } from 'lucide-react';
import { Outlet, useNavigate, useParams } from 'react-router';
import { BaseLayout } from '../../layout/pages/BaseLayout';
import { useResponsiveQueries } from '../../shared/hooks/useResponsiveQueries';
import useIsBaseRoute from '../../shared/hooks/useIsNavigationMode';
import { useNetworkQuery, useNetworksInfiniteQuery } from './queries/networkQueries';
import { createRoute } from '../../layout/navigation/helpers/routingHelpers';
import useTenant from '../../shared/hooks/useTenant';
import useCreateBreadcrumbItems from '../../layout/navigation/BreadcrumbNavigator/hooks/useCreateBreadcrumbItems';

export function NetworkDetailLayout() {
  const { isSmallScreen } = useResponsiveQueries();
  const isBaseRoute = useIsBaseRoute();

  const navigate = useNavigate();
  const { activeTenant, tenantViewMode } = useTenant();

  const networksQuery = useNetworksInfiniteQuery({
    tenantId: tenantViewMode === 'specific' ? activeTenant?.id : undefined,
  });

  const { breadcrumbItems } = useCreateBreadcrumbItems('networks', networksQuery);

  const { id } = useParams();
  const network = useNetworkQuery(id);

  if (!network.data) return null;

  return (
    <BaseLayout
      title={network.data.name}
      contentAfterHeader={[
        {
          id: 'back',
          content: (
            <Undo2
              className="cursor-pointer"
              onClick={() => navigate(createRoute('networks/list', activeTenant.id))}
            />
          ),
        },
      ]}
      breadcrumbItems={breadcrumbItems}
    >
      {(!isSmallScreen || !isBaseRoute) && (
        <div className="flex-1 h-full overflow-hidden">
          <Outlet />
        </div>
      )}
    </BaseLayout>
  );
}
