/* eslint-disable no-bitwise */
import {
  $getRoot,
  $getSelection,
  $setSelection,
  ElementFormatType,
  LexicalEditor,
  LexicalNode,
  ParagraphNode,
  RootNode,
  TextNode,
} from 'lexical';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { LinkNode } from '@lexical/link';
import { ListNode, ListItemNode } from '@lexical/list';
import { $convertFromMarkdownString, Transformer } from '@lexical/markdown';
import React, { useMemo } from 'react';
import { MentionNode } from '../mentions/MentionNode';
import { cutText } from '../../utils/stringUtils';
import { ImageNode } from '../images/ImageNode';
import ImageComponent from '../../components/images/Image';
import { ContactUserProfileLink } from '../../../features/Contacts/ContactUserProfileLink';

function renderRichText() {
  const renderText = (_node: TextNode) => {
    switch (_node.getFormat()) {
      case 1: // bold
        return <strong key={_node.getKey()}>{_node.getTextContent()}</strong>;
      case 1 << 1: // italic
        return <em key={_node.getKey()}>{_node.getTextContent()}</em>;
      case 1 << 2: // strikethrough
        return <s key={_node.getKey()}>{_node.getTextContent()}</s>;
      case 1 << 3: // underline
        return <u key={_node.getKey()}>{_node.getTextContent()}</u>;
      case 1 << 4: // code
        return <code key={_node.getKey()}>{_node.getTextContent()}</code>;
      case 1 << 5: // subscript
        return <sub key={_node.getKey()}>{_node.getTextContent()}</sub>;
      case 1 << 6: // superscript
        return <sup key={_node.getKey()}>{_node.getTextContent()}</sup>;
      default:
        return <React.Fragment key={_node.getKey()}>{_node.getTextContent()}</React.Fragment>;
    }
  };

  const renderStyle = (format: ElementFormatType): React.CSSProperties => {
    switch (format) {
      case '':
        return {};
      case 'left':
        return { textAlign: 'left' };
      case 'center':
        return { textAlign: 'center' };
      case 'right':
        return { textAlign: 'right' };
      case 'justify':
        return { textAlign: 'justify' };
      default:
        // eslint-disable-next-line no-console
        console.log('unknown text-align', format);
        return {};
    }
  };

  const renderNode = (_node: LexicalNode): React.ReactNode => {
    switch (_node.getType()) {
      case 'root':
        return <>{(_node as RootNode).getChildren().map((k) => renderNode(k))}</>;
      case 'heading': {
        const headingNode = _node as HeadingNode;
        return React.createElement(
          headingNode.getTag(),
          { key: headingNode.getKey() },
          headingNode.getChildren().map((k) => renderNode(k)),
        );
      }
      case 'list': {
        const listNode = _node as ListNode;
        return React.createElement(
          listNode.getTag(),
          { key: listNode.getKey(), className: 'list-disc ml-4' },
          listNode.getChildren().map((k) => renderNode(k)),
        );
      }
      case 'text': {
        const textNode = _node as TextNode;
        return renderText(textNode);
      }
      case 'quote': {
        const quoteNode = _node as QuoteNode;
        return (
          <blockquote key={quoteNode.getKey()}>
            {quoteNode.getChildren().map((k) => renderNode(k))}
          </blockquote>
        );
      }
      case 'paragraph': {
        const paragraphNode = _node as ParagraphNode;
        return (
          <p key={paragraphNode.getKey()} style={renderStyle(paragraphNode.getFormatType())}>
            {paragraphNode.getChildren().map((k) => renderNode(k))}
          </p>
        );
      }
      case 'listitem': {
        const listItemNode = _node as ListItemNode;
        return (
          <li key={listItemNode.getKey()}>
            {listItemNode.getChildren().map((k) => renderNode(k))}
          </li>
        );
      }
      case 'link':
      case 'autolink': {
        const linkNode = _node as LinkNode;
        return (
          <a
            key={linkNode.getKey()}
            className="text-cyan"
            href={linkNode.getURL()}
            title={linkNode.getTitle() ?? undefined}
            target={linkNode.getTarget() ?? undefined}
            rel={linkNode.getRel() ?? undefined}
          >
            {linkNode.getChildren().map((k) => renderNode(k))}
          </a>
        );
      }
      case 'linebreak':
        return <br key={_node.getKey()} />;
      case 'mention': {
        const mentionNode = _node as MentionNode;
        const mentionClassName = 'bg-blue-light text-blue-dark rounded-md px-2 py-0.5 font-medium';
        if (mentionNode.isMention()) {
          return (
            <ContactUserProfileLink
              key={mentionNode.getKey()}
              userId={mentionNode.getReference()}
              className={mentionClassName}
            >
              {mentionNode.getTextContent()}
            </ContactUserProfileLink>
          );
        }
        if (mentionNode.isAssistant()) {
          return (
            <span key={mentionNode.getKey()} className={mentionClassName}>
              {mentionNode.getTextContent()}
            </span>
          );
        }
        return (
          <span key={mentionNode.getKey()} className={mentionClassName}>
            {mentionNode.getTextContent()}
          </span>
        );
      }
      case 'image': {
        const imageNode = _node as ImageNode;
        return (
          <ImageComponent
            key={imageNode.getKey()}
            src={imageNode.getSrc()}
            alt={imageNode.getAlt()}
            maxHeight="max-h-80"
          />
        );
      }
      default:
        // eslint-disable-next-line no-console
        console.warn('unknown type', _node.getType());
        return '';
    }
  };

  return renderNode($getRoot());
}

function renderPlainText(maxLength?: number) {
  let text = $getRoot().getTextContent();
  if (maxLength) {
    text = cutText(text, maxLength);
  }
  return <>{text}</>;
}

interface IProps {
  editor?: LexicalEditor;
  markdown?: string;
  asPlainText?: boolean;
  maxLength?: number;
  transformers?: Transformer[];
}

/**
 * Render markdown to JSX using the lexical editor.
 * @param editor lexical editor
 * @param markdown markdown string
 * @returns rendered JSX
 */
export function useLexicalMarkdownContent({
  editor,
  markdown,
  asPlainText = false,
  maxLength,
  transformers = [],
}: IProps) {
  const content = useMemo(() => {
    if (!editor || !markdown) {
      return null;
    }
    let _content: React.ReactNode;
    editor.update(
      () => {
        const previousEditorState = editor.getEditorState();
        const previousSelection = $getSelection();
        try {
          // Load the editor state with the markdown string
          $convertFromMarkdownString(markdown, transformers);
          // Render JSX from the editor state
          _content = asPlainText ? renderPlainText(maxLength) : renderRichText();
        } finally {
          // Restore the editor state
          editor.setEditorState(previousEditorState);
          $setSelection(previousSelection);
        }
      },
      { discrete: true },
    );
    return _content;
  }, [markdown, editor, transformers, asPlainText, maxLength]);

  return content;
}
