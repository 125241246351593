import { Slash } from 'lucide-react'; // or any icon library you use
import { Fragment } from 'react';
import { BreadcrumbItem, IBreadcrumbItem } from './BreadcrumbItem';
import { useResponsiveQueries } from '../../../shared/hooks/useResponsiveQueries';
import { cn } from '../../../lib/utils';

export interface BreadCrumbNavigatorProps {
  items: IBreadcrumbItem[];
}

export function BreadcrumbNavigator(props: BreadCrumbNavigatorProps) {
  const { items } = props;

  return (
    <nav className="flex items-center space-x-2" aria-label="Breadcrumb">
      {items.map((item, index) => (
        <Fragment key={item.id}>
          <BreadcrumbItem {...item} />
          {index < items.length - 1 && <Slash className="w-3 h-3 text-light mt-1" />}
        </Fragment>
      ))}
    </nav>
  );
}
