import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useDispatchApiError } from '../../../shared/hooks/useDispatchApiError';
import {
  createProject,
  deleteProject,
  getProjects,
  getProject,
  updateProject,
} from '../services/projectService';
import { ICreateUpdateProjectDto } from '../models/IProjectDto';
import QueryParam from '../../../shared/utils/query-string-builder/QueryParam';
import { useInfiniteApiQuery } from '../../../shared/hooks/useInfiniteApiQuery';

type ProjectSearchField = 'Name';

interface IProjectsKeyProps {
  id?: string;
  tenantId?: string;
  limit?: number;
  searchTerm?: string;
  searchFields?: ProjectSearchField[];
}

export const projectKeys = {
  all: ['projects'] as const,
  lists: () => [...projectKeys.all, 'list'] as const,
  paged: (props: IProjectsKeyProps) => [...projectKeys.lists(), 'paged', props] as const,
  list: (props: IProjectsKeyProps) => [...projectKeys.lists(), props] as const,
  details: () => [...projectKeys.all, 'detail'] as const,
  detail: (id: string) => [...projectKeys.details(), id] as const,
};

const STALE_TIME = 1000 * 60; // 1 minute, pipelines aren't updated that often.

const projectsBaseQuery = (props: IProjectsKeyProps) => ({
  queryKey: projectKeys.list(props),
  queryFn: () =>
    getProjects(
      new QueryParam('limit', props.limit || 250),
      new QueryParam('searchTerm', props.searchTerm),
      new QueryParam('tenantId', props.tenantId),
      ...(props.searchFields || []).map((field) => new QueryParam('searchFields', field)),
    ),
});

export function useProjectsQuery(props: IProjectsKeyProps) {
  return useQuery({
    ...projectsBaseQuery(props),
    placeholderData: keepPreviousData,
  });
}

export function useProjectsInfiniteQuery(props: IProjectsKeyProps = {}) {
  const { limit = 150, searchTerm = '', searchFields = [], tenantId } = props;
  return useInfiniteApiQuery({
    queryKey: projectKeys.paged({ limit, searchTerm, searchFields, tenantId }),
    queryFn: () =>
      getProjects(
        new QueryParam('limit', limit),
        new QueryParam('searchTerm', searchTerm),
        new QueryParam('tenantId', tenantId),
        ...searchFields.map((field) => new QueryParam('searchFields', field)),
      ),
  });
}

export const projectBaseQuery = (id: string) => ({
  queryKey: projectKeys.detail(id),
  queryFn: () => getProject(id),
  staleTime: STALE_TIME,
});

export function useProjectQuery(id?: string) {
  return useQuery({
    ...projectBaseQuery(id as string),
    enabled: !!id,
  });
}

export interface ICreateUpdateProjectProps {
  project: ICreateUpdateProjectDto;
  id?: string;
}

export function useCreateUpdateProjectMutation() {
  const queryClient = useQueryClient();
  const dispatchError = useDispatchApiError();
  return useMutation({
    mutationFn: ({ project, id }: ICreateUpdateProjectProps) => {
      if (id) {
        return updateProject(project, id).then((response) => response.data);
      }
      return createProject(project);
    },
    onSuccess: (_, { id }) => {
      queryClient.invalidateQueries({ queryKey: projectKeys.lists() });
      if (id) {
        queryClient.invalidateQueries({ queryKey: projectKeys.detail(id) });
      }
    },
    onError: dispatchError,
    retry: 3,
  });
}

export function useDeleteProjectMutation() {
  const queryClient = useQueryClient();
  const dispatchError = useDispatchApiError();
  return useMutation({
    mutationFn: ({ projectId }: { projectId: string }) =>
      deleteProject(projectId).then((response) => response.data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: projectKeys.lists() });
      // queryClient.invalidateQueries({
      //   queryKey: projectKpiKeys.detail({ projectId, periodStartTime: new Date() }),
      // });
    },
    onError: dispatchError,
  });
}
