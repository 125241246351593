import { ChevronDown } from 'lucide-react';
import { useResponsiveQueries } from '../../../shared/hooks/useResponsiveQueries';
import { cn } from '../../../lib/utils';
import { abbreviateString } from '../../../shared/utils/stringUtils';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../../../shared/components/drop-down-menu/DropdownMenu';

export interface IBreadcrumbItem {
  id: string;
  name: string;
  isDisabled?: boolean;
  onClick?: () => void;
  siblings?: IBreadcrumbItem[];
}

export function BreadcrumbItem(item: IBreadcrumbItem) {
  const { name, onClick, isDisabled, siblings } = item;
  const { isSmallScreen } = useResponsiveQueries();

  const button = (
    <button
      type="button"
      onClick={onClick}
      className={cn(
        'text-sm font-medium text-cyan',
        isDisabled && 'text-light pointer-events-none',
      )}
    >
      {isSmallScreen ? abbreviateString(name) : name}
    </button>
  );

  if (siblings && siblings.length > 0) {
    return (
      <>
        {button}
        {siblings ? (
          <DropdownMenu>
            <DropdownMenuTrigger>
              <ChevronDown className="w-4 h-4 text-light mt-1 cursor-pointer" />
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              {siblings.map((sibling) => (
                <DropdownMenuItem key={sibling.id} onClick={sibling.onClick}>
                  {sibling.name}
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        ) : null}
      </>
    );
  }

  return button;
}
