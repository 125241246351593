import { EditorThemeClasses } from 'lexical';
import {
  LINK,
  BOLD_STAR,
  BOLD_ITALIC_STAR,
  BOLD_ITALIC_UNDERSCORE,
  ITALIC_STAR,
  ITALIC_UNDERSCORE,
  BOLD_UNDERSCORE,
  UNORDERED_LIST,
} from '@lexical/markdown';
import { LinkNode, AutoLinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import { IMAGE, MENTION } from '../../../shared/lexical/markdown/LexicalMarkdownTransformers';
import { MentionNode } from '../../../shared/lexical/mentions/MentionNode';
import { ImageNode } from '../../../shared/lexical/images/ImageNode';

// Order matters becuase of the nature of markdown. The first transformer to match will be used.
export const MARKDOWN_TRANSFORMERS = [
  MENTION,
  IMAGE,
  LINK,
  BOLD_STAR,
  BOLD_ITALIC_STAR,
  BOLD_ITALIC_UNDERSCORE,
  BOLD_UNDERSCORE,
  ITALIC_STAR,
  ITALIC_UNDERSCORE,
  UNORDERED_LIST,
];

const theme: EditorThemeClasses = {
  link: 'text-cyan',
  autolink: 'text-cyan',
  mention: 'bg-blue-light',
};

export const MESSAGE_EDITOR_CONFIG = {
  namespace: 'MessageEditor',
  onError: (error: unknown) => {
    throw error;
  },
  nodes: [AutoLinkNode, LinkNode, MentionNode, ImageNode, ListNode, ListItemNode],
  theme,
};
