import { AtSymbolIcon, BeakerIcon, LinkIcon, ShareIcon } from '@heroicons/react/24/outline';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { PlusCircleIcon } from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom';
import { useFileDropContext } from '../../../shared/components/file/FileDropContainer';
import { INSERT_MENTION_COMMAND } from '../../../shared/lexical/mentions/MentionsPlugin';
import { MessageEditorAction } from './messageEditorMenuActions';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuDivider,
  DropdownMenuIcon,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../../../shared/components/drop-down-menu/DropdownMenu';

export type MessageEditorActionProps = {
  externalMenuActions?: MessageEditorAction[];
};

export function MessageEditorActionMenu({ externalMenuActions = [] }: MessageEditorActionProps) {
  const fileDropContext = useFileDropContext();
  const isEditMode = false;
  const [editor] = useLexicalComposerContext();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <PlusCircleIcon className="h-6 w-6 text" />
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {fileDropContext && !isEditMode && (
          <DropdownMenuItem onClick={fileDropContext.openFileDialog}>
            <DropdownMenuIcon icon={LinkIcon} />
            Attach File
          </DropdownMenuItem>
        )}
        <DropdownMenuItem
          onClick={() => {
            editor.dispatchCommand(INSERT_MENTION_COMMAND, 'share');
            setTimeout(() => {
              editor.focus();
            }, 500);
          }}
        >
          <DropdownMenuIcon icon={ShareIcon} />
          Share Contact
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={() => {
            editor.dispatchCommand(INSERT_MENTION_COMMAND, 'mention');
            setTimeout(() => {
              editor.focus();
            }, 500);
          }}
        >
          <DropdownMenuIcon icon={AtSymbolIcon} />
          Mention
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={() => {
            editor.dispatchCommand(INSERT_MENTION_COMMAND, 'assistant');
            setTimeout(() => {
              editor.focus();
            }, 500);
          }}
        >
          <DropdownMenuIcon icon={BeakerIcon} />
          Ask assistant
        </DropdownMenuItem>
        {externalMenuActions.length > 0 && <DropdownMenuDivider />}
        {externalMenuActions.map((action) => (
          <DropdownMenuItem key={action.to} asChild>
            <Link to={action.to} onClick={action.onClick}>
              <DropdownMenuIcon icon={action.icon} />
              {action.label}
            </Link>
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
