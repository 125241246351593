import { useLocation } from 'react-router-dom';

export function useIsAllRoute() {
  const { pathname } = useLocation();
  return pathname.includes('/list/all');
}

export function useIsDetailRoute() {
  const { pathname } = useLocation();
  return pathname.includes('/detail/');
}
