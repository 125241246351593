import { useNavigate } from 'react-router';
import { useMemo } from 'react';
import useTenants from '../../../../shared/hooks/useTenant';
import { useTenantsQuery } from '../../../../features/Companies/queries/tenantQueries';
import { useIsDetailRoute } from '../../../../shared/hooks/useIsRoute';
import { createRoute } from '../../helpers/routingHelpers';
import { capitalizeFirstLetter } from '../../../../shared/utils/stringUtils';
import { IBreadcrumbItem } from '../BreadcrumbItem';
import { UseInfiniteApiQueryResult } from '../../../../shared/hooks/useInfiniteApiQuery';

export type ResourceType = 'contacts' | 'networks';

/**
 * A hook that returns the breadcrumb items for the resource navigation
 * As of now, the resource navigation is only used for contacts and networks
 * @param resourceType - The type of the resource
 * @param resources - The resources to create the breadcrumb items for
 * @returns The breadcrumb items
 */

interface Identifiable {
  id: string;
  name: string;
}

export default function useCreateBreadcrumbItems<T extends Identifiable>(
  resourceType: ResourceType,
  resources?: UseInfiniteApiQueryResult<T>,
) {
  const navigate = useNavigate();
  const { activeTenant, setActiveTenant, tenantViewMode, setTenantViewMode } = useTenants();
  const tenants = useTenantsQuery().data?.pages.flat();

  const isDetailRoute = useIsDetailRoute();

  const breadcrumbItems = useMemo<IBreadcrumbItem[]>(() => {
    const breadcrumbs: IBreadcrumbItem[] = [];

    if (activeTenant) {
      const tenantBreadcrumbItem: IBreadcrumbItem = {
        name: tenantViewMode === 'all' ? `All ${resourceType}` : activeTenant.name,
        id: activeTenant.id,
        siblings: [
          {
            id: resourceType,
            name: `All ${resourceType}`,
            onClick: () => {
              setTenantViewMode('all');
              navigate(createRoute(`${resourceType}/list/all`));
            },
          },
          ...(tenants
            ? tenants.map((tenant) => ({
                id: tenant.id,
                name: tenant.name,
                onClick: () => {
                  setActiveTenant(tenant);
                  setTenantViewMode('specific');
                  navigate(createRoute(`${resourceType}/list`, tenant.id));
                },
              }))
            : []),
        ],
        onClick: () => {
          if (tenantViewMode === 'all') {
            navigate(createRoute(`${resourceType}/list/all`));
          } else {
            navigate(createRoute(`${resourceType}/list`, activeTenant.id));
          }
        },
      };
      breadcrumbs.push(tenantBreadcrumbItem);
    }

    // If we pass in a resources object and we are on a detail route, we want to be
    // able to navigate between the resources
    if (isDetailRoute && resources?.data) {
      const detailBreadcrumbItem: IBreadcrumbItem = {
        id: `${resourceType}-details`,
        name: capitalizeFirstLetter(resourceType),
        siblings: resources?.data
          ? resources.data.pages.flat().map((sibling) => ({
              id: sibling.id,
              name: sibling.name,
              onClick: () => navigate(createRoute(`${resourceType}/detail`, sibling.id)),
            }))
          : [],
      };
      breadcrumbs.push(detailBreadcrumbItem);
    }

    // If we are on a detail route and we don't have any resources,
    // we want to show which resource we are viewing
    if (isDetailRoute && !resources?.data) {
      breadcrumbs.push({
        id: `${resourceType}-details`,
        name: capitalizeFirstLetter(resourceType),
        isDisabled: true,
      });
    }

    return breadcrumbs;
  }, [tenants, activeTenant, tenantViewMode, isDetailRoute]);

  return { breadcrumbItems };
}
