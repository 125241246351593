import ProfileAvatar from '../../../../../shared/components/avatar/ProfileAvatar';
import useEntityManifest from '../../../../../shared/hooks/useEntityManifest';
import { IActionItemCustomIcon } from '../../../../../shared/model/IActionItem';
import { ResourceType } from '../../../../../shared/model/ResourceType';
import { MappedResourceTypes } from '../PipelineCreateItemPopover';

export function usePipelineItemActions(
  onSelect: React.Dispatch<React.SetStateAction<MappedResourceTypes | null>>,
) {
  const pipelineItemManifest = useEntityManifest(ResourceType.PipelineItem);
  const networkManifest = useEntityManifest(ResourceType.Network);
  const contactManifest = useEntityManifest(ResourceType.Contact);
  const opportunityManifest = useEntityManifest(ResourceType.Opportunity);

  const menuActions: IActionItemCustomIcon[] = [
    {
      id: 'addOpportunity',
      title: 'Add opportunity',
      icon: (
        <ProfileAvatar
          avatarProps={{
            src: opportunityManifest.icon,
            widthClass: 'w-5',
            heightClass: 'h-5',
          }}
        />
      ),
      addDividerAfter: true,
      onClick: () => onSelect(ResourceType.Opportunity),
    },
    {
      id: 'addItem',
      title: 'Add item',
      icon: (
        <ProfileAvatar
          avatarProps={{
            src: pipelineItemManifest.icon,
            widthClass: 'w-5',
            heightClass: 'h-5',
          }}
        />
      ),
      onClick: () => onSelect(ResourceType.PipelineItem),
    },
    {
      id: 'addNetwork',
      title: 'Add network',
      icon: (
        <ProfileAvatar
          avatarProps={{
            src: networkManifest.icon,
            widthClass: 'w-5',
            heightClass: 'h-5',
          }}
        />
      ),
      onClick: () => onSelect(ResourceType.Network),
    },
    {
      id: 'addContact',
      title: 'Add contact',
      icon: (
        <ProfileAvatar
          avatarProps={{
            src: contactManifest.icon,
            widthClass: 'w-5',
            heightClass: 'h-5',
          }}
        />
      ),
      onClick: () => onSelect(ResourceType.Contact),
    },
  ];

  return menuActions;
}
