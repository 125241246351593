export function stringBuilder(separator?: string) {
  const strings: string[] = [];
  const api = {
    add: (value?: string) => {
      if (value) strings.push(value);
      return api;
    },
    toString: () => strings.join(separator || ' '),
  };

  return api;
}

export const cutText = (text: string, maxLength: number) => {
  const actualMaxLength = maxLength - 3;
  if (text.length <= maxLength) {
    return text;
  }

  const lastSpaceIndex = text.slice(0, actualMaxLength).lastIndexOf(' ');
  const truncatedText = `${text.slice(0, lastSpaceIndex)}...`;
  return truncatedText;
};

export function abbreviateString(input: string): string {
  if (input.length <= 8) {
    return input;
  }
  return `${input.substring(0, 8)}...`;
}

export function capitalizeFirstLetter(str: string) {
  if (!str) return '';
  return str[0].toUpperCase() + str.slice(1);
}
