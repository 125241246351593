import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useCallback, useEffect, useState } from 'react';
import {
  $getRoot,
  $getSelection,
  $isRangeSelection,
  $isTextNode,
  COMMAND_PRIORITY_EDITOR,
  COMMAND_PRIORITY_LOW,
  COMMAND_PRIORITY_NORMAL,
  KEY_ARROW_DOWN_COMMAND,
  KEY_ARROW_UP_COMMAND,
  KEY_ENTER_COMMAND,
  KEY_TAB_COMMAND,
  LexicalCommand,
  LexicalEditor,
  RangeSelection,
  TextNode,
  createCommand,
  Point,
} from 'lexical';
import { mergeRegister } from '@lexical/utils';
import {
  autoUpdate,
  flip,
  inline,
  shift,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react';
import contactIcon from '../../../assets/images/contact-icon.svg';
import { useContactsForMentionShareQuery } from '../../../features/Contacts/queries/contactQueries';
import { $createMentionNode, MentionType } from './MentionNode';
import { MENTIONS_REGEX } from '../../constants/RegexValidations';
import {
  useConversationParticipantsForMentionQuery,
  useConversationQuery,
} from '../../../features/Messaging/queries/conversationQueries';
import ScrollBarWrapper from '../../components/scrolling/ScrollBarWrapper';
import { ScrollIntoView } from '../../components/scrolling/ScrollIntoView';
import getProfileImageUrl from '../../services/profileImageService';
import ProfileAvatar from '../../components/avatar/ProfileAvatar';
import { cn } from '../../../lib/utils';

export type TextMatch = {
  matchingString: string;
  replaceableString: string;
};

export interface IProps {
  conversationId: string;
  mentionType?: MentionType;
}
export const INSERT_MENTION_COMMAND: LexicalCommand<MentionType> =
  createCommand('INSERT_MENTION_COMMAND');

class MentionTypeaheadOption {
  reference: string;

  mentionType: MentionType;

  displayName: string;

  photoUrl: string;

  constructor(reference: string, mentionType: MentionType, displayName: string, photoUrl: string) {
    this.reference = reference;
    this.mentionType = mentionType;
    this.displayName = displayName;
    this.photoUrl = photoUrl;
  }
}

function getTextUpToPoint(point: Point): string | null {
  if (point.type !== 'text') {
    return null;
  }
  const anchorNode = point.getNode();
  if (!$isTextNode(anchorNode) || !anchorNode.isSimpleText()) {
    return null;
  }
  const anchorOffset = point.offset;
  return anchorNode.getTextContent().slice(0, anchorOffset);
}

function getMentionTriggerText(selection: RangeSelection): string {
  const startingPoint = selection.isBackward() ? selection.focus : selection.anchor;
  const textUpToPoint = getTextUpToPoint(startingPoint);
  const triggerText = !textUpToPoint || textUpToPoint.endsWith(' ') ? '@' : ' @';
  return triggerText;
}

// Copied a number of functions from Lexicals typeahead menu plugin because their menu
// wasn't up to standards and I wanted to use Floating UI instead.
// However, their private functions for getting the matched text and replacing the node
// are useful and hard to come up with on your own.

// Copied from https://github.com/facebook/lexical/blob/main/packages/lexical-react/src/LexicalTypeaheadMenuPlugin.tsx
function getTextUpToAnchor(selection: RangeSelection): string | null {
  const { anchor } = selection;
  return getTextUpToPoint(anchor);
}

// Copied from https://github.com/facebook/lexical/blob/main/packages/lexical-react/src/LexicalTypeaheadMenuPlugin.tsx
function getQueryTextForSearch(editor: LexicalEditor): string | null {
  let text = null;
  editor.getEditorState().read(() => {
    const selection = $getSelection();
    if (!$isRangeSelection(selection)) {
      return;
    }
    text = getTextUpToAnchor(selection);
  });
  return text;
}

// Copied from https://github.com/facebook/lexical/blob/main/packages/lexical-react/src/LexicalTypeaheadMenuPlugin.tsx
function isSelectionOnEntityBoundary(editor: LexicalEditor, offset: number): boolean {
  if (offset !== 0) {
    return false;
  }
  return editor.getEditorState().read(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const { anchor } = selection;
      const anchorNode = anchor.getNode();
      const prevSibling = anchorNode.getPreviousSibling();
      return $isTextNode(prevSibling) && prevSibling.isTextEntity();
    }
    return false;
  });
}

// Copied from https://github.com/facebook/lexical/blob/main/packages/lexical-react/src/LexicalTypeaheadMenuPlugin.tsx
function tryToPositionRange(leadOffset: number, range: Range): boolean {
  const domSelection = window.getSelection();
  if (domSelection === null || !domSelection.isCollapsed) {
    return false;
  }
  const { anchorNode } = domSelection;
  const startOffset = leadOffset;
  const endOffset = domSelection.anchorOffset;

  if (anchorNode == null || endOffset == null) {
    return false;
  }

  try {
    range.setStart(anchorNode, startOffset);
    range.setEnd(anchorNode, endOffset);
  } catch (error) {
    return false;
  }

  return true;
}

/**
 * Walk backwards along user input and forward through entity title to try
 * and replace more of the user's text with entity.
 *
 * Copied from https://github.com/facebook/lexical/blob/main/packages/lexical-react/src/shared/LexicalMenu.ts
 */
function getFullMatchOffset(documentText: string, entryText: string, offset: number): number {
  let triggerOffset = offset;
  for (let i = triggerOffset; i <= entryText.length; i++) {
    if (documentText.slice(-i) === entryText.slice(0, i)) {
      triggerOffset = i;
    }
  }
  return triggerOffset;
}

/**
 * Split Lexical TextNode and return a new TextNode only containing matched text.
 * Common use cases include: removing the node, replacing with a new node.
 *
 * Copied from https://github.com/facebook/lexical/blob/main/packages/lexical-react/src/shared/LexicalMenu.ts
 */
function $splitNodeContainingQuery({
  replaceableString,
  matchingString,
}: TextMatch): TextNode | null {
  const selection = $getSelection();
  if (!$isRangeSelection(selection) || !selection.isCollapsed()) {
    return null;
  }
  const { anchor } = selection;
  if (anchor.type !== 'text') {
    return null;
  }
  const anchorNode = anchor.getNode();
  if (!anchorNode.isSimpleText()) {
    return null;
  }
  const selectionOffset = anchor.offset;
  const textContent = anchorNode.getTextContent().slice(0, selectionOffset);
  const characterOffset = replaceableString.length;
  const queryOffset = getFullMatchOffset(textContent, matchingString, characterOffset);
  const startOffset = selectionOffset - queryOffset;
  if (startOffset < 0) {
    return null;
  }
  let newNode;
  if (startOffset === 0) {
    [newNode] = anchorNode.splitText(selectionOffset);
  } else {
    [, newNode] = anchorNode.splitText(startOffset, selectionOffset);
  }

  return newNode;
}

const checkForMentionMatch = (text: string) => {
  const match = MENTIONS_REGEX.exec(text);
  if (match === null) return null;

  const matchingString = match[3];

  const leadingWhitespaceIfAny = match[1];
  return {
    leadOffset: match.index + leadingWhitespaceIfAny.length,
    matchingString,
    replaceableString: match[2],
  };
};

function MentionMenuItem({
  option,
  isSelected,
  onClick,
}: {
  option: MentionTypeaheadOption;
  isSelected: boolean;
  onClick: (option: MentionTypeaheadOption) => void;
}) {
  return (
    <ScrollIntoView
      as="div"
      role="menuitem"
      key={option.reference}
      active={isSelected}
      className={cn(
        'relative flex items-center gap-3 cursor-default select-none transition-colors hover:bg-zinc-100 rounded-md px-2 py-2 text text-sm outline-none scroll-mt-9',
        isSelected && 'bg-zinc-100',
      )}
      tabIndex={-1}
      onClick={() => onClick(option)}
    >
      <ProfileAvatar
        avatarProps={{
          src: option.photoUrl,
          alt: option.displayName,
          widthClass: 'w-5',
          heightClass: 'h-5',
        }}
      />
      {option.displayName}
    </ScrollIntoView>
  );
}

function MentionMenuLabel({ children }: { children: React.ReactNode }) {
  return <header className="px-2 py-1.5 text text-sm font-semibold">{children}</header>;
}

interface IMentionsMenuSectionProps {
  header: string;
  mentionOptions: MentionTypeaheadOption[];
  selectedIndex: number | null;
  indexOffset?: number;
  onSelectOption: (option: MentionTypeaheadOption) => void;
}

function MentionsMenuSection({
  header,
  mentionOptions,
  selectedIndex,
  indexOffset = 0,
  onSelectOption,
}: IMentionsMenuSectionProps) {
  return (
    <div>
      <MentionMenuLabel>{header}</MentionMenuLabel>
      <ul>
        {mentionOptions.map((option, index) => (
          <MentionMenuItem
            key={option.reference}
            option={option}
            isSelected={index + indexOffset === selectedIndex}
            onClick={onSelectOption}
          />
        ))}
      </ul>
    </div>
  );
}

interface IMentionsMenuProps {
  mentionOptions?: MentionTypeaheadOption[];
  shareOptions?: MentionTypeaheadOption[];
  assistantOptions?: MentionTypeaheadOption[];
  onSelectOption: (option: MentionTypeaheadOption) => void;
}

function MentionsMenu({
  mentionOptions,
  shareOptions,
  assistantOptions,
  onSelectOption,
}: IMentionsMenuProps) {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [editor] = useLexicalComposerContext();

  const totalLength =
    (mentionOptions?.length ?? 0) + (shareOptions?.length ?? 0) + (assistantOptions?.length ?? 0);
  const options = (mentionOptions ?? []).concat(shareOptions ?? []).concat(assistantOptions ?? []);

  useEffect(() => {
    setSelectedIndex(0);
  }, [mentionOptions, shareOptions, assistantOptions]);

  useEffect(
    () =>
      mergeRegister(
        editor.registerCommand<KeyboardEvent>(
          KEY_ARROW_DOWN_COMMAND,
          (event) => {
            if (totalLength <= 0) return true;

            setSelectedIndex((index) => (index === null ? 0 : (index + 1) % totalLength));
            event.preventDefault();
            event.stopImmediatePropagation();
            return true;
          },
          COMMAND_PRIORITY_LOW,
        ),
        editor.registerCommand<KeyboardEvent>(
          KEY_ARROW_UP_COMMAND,
          (event) => {
            if (totalLength <= 0) return true;

            setSelectedIndex((index) =>
              index === null ? 0 : (index - 1 + totalLength) % totalLength,
            );
            event.preventDefault();
            event.stopImmediatePropagation();
            return true;
          },
          COMMAND_PRIORITY_LOW,
        ),
        editor.registerCommand<KeyboardEvent | null>(
          KEY_ENTER_COMMAND,
          (event) => {
            if (!options || selectedIndex === null || options[selectedIndex] === null) {
              return false;
            }
            if (event !== null) {
              event.preventDefault();
              event.stopImmediatePropagation();
            }
            onSelectOption(options[selectedIndex]);
            return true;
          },
          COMMAND_PRIORITY_NORMAL,
        ),
        editor.registerCommand<KeyboardEvent>(
          KEY_TAB_COMMAND,
          (event) => {
            if (!options || selectedIndex === null || options[selectedIndex] === null) {
              return false;
            }
            event.preventDefault();
            event.stopImmediatePropagation();
            onSelectOption(options[selectedIndex]);
            return true;
          },
          COMMAND_PRIORITY_LOW,
        ),
      ),
    [editor, options, selectedIndex],
  );

  return (
    <>
      {totalLength > 0 && (
        <ScrollBarWrapper className="max-h-96">
          {mentionOptions && mentionOptions.length > 0 && (
            <MentionsMenuSection
              header="Mention"
              mentionOptions={mentionOptions}
              selectedIndex={selectedIndex}
              onSelectOption={onSelectOption}
            />
          )}
          {shareOptions && shareOptions.length > 0 && (
            <MentionsMenuSection
              header="Share contact"
              mentionOptions={shareOptions}
              selectedIndex={selectedIndex}
              indexOffset={shareOptions?.length || 0}
              onSelectOption={onSelectOption}
            />
          )}
          {assistantOptions && assistantOptions.length > 0 && (
            <MentionsMenuSection
              header="Ask assistant"
              mentionOptions={assistantOptions}
              selectedIndex={selectedIndex}
              indexOffset={assistantOptions?.length || 0}
              onSelectOption={onSelectOption}
            />
          )}
        </ScrollBarWrapper>
      )}
    </>
  );
}

export function MentionsPlugin({ conversationId }: IProps) {
  const [editor] = useLexicalComposerContext();
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [match, setMatch] = useState<TextMatch | null>(null);
  const [typeFilter, setTypeFilter] = useState<MentionType | null>(null);

  const conversationQuery = useConversationQuery(conversationId);
  const mentionOptionsQuery = useConversationParticipantsForMentionQuery(
    conversationId,
    searchTerm,
    (participant) =>
      new MentionTypeaheadOption(
        participant.userId,
        'mention',
        participant.displayName,
        getProfileImageUrl(participant.accountId), // TODO: Get profile image from participant
      ),
  );

  const shareContactOptionsQuery = useContactsForMentionShareQuery(
    searchTerm,
    (contact) =>
      new MentionTypeaheadOption(contact.id, 'share', `${contact.displayName}`, contact.photoUrl),
    conversationQuery.data?.participants.map((participant) => participant.userId) ?? [],
  );

  const assistantChoices = [
    new MentionTypeaheadOption(
      '6aa0bf9c-0fa5-485a-bea5-eb7f82d8100a',
      'assistant',
      'File assistant',
      contactIcon,
    ),
  ];

  const { refs, floatingStyles, context } = useFloating({
    placement: 'top-start',
    open: isMenuOpen,
    onOpenChange: setIsMenuOpen,
    middleware: [inline(), flip(), shift()],
    whileElementsMounted: autoUpdate,
  });

  const dismiss = useDismiss(context);
  const { getFloatingProps } = useInteractions([dismiss]);

  const openMenu = useCallback((_match: TextMatch) => {
    setIsMenuOpen(true);
    setMatch(_match);
  }, []);

  const closeMenu = useCallback(() => {
    setIsMenuOpen(false);
    setMatch(null);
    setTypeFilter(null);
  }, []);

  const handleSelectOption = useCallback(
    (selectedOption: MentionTypeaheadOption) => {
      editor.update(() => {
        const mentionNode = $createMentionNode(
          selectedOption.reference,
          selectedOption.displayName,
          selectedOption.mentionType,
        );
        const nodeToReplace = match ? $splitNodeContainingQuery(match) : null;
        if (nodeToReplace) {
          nodeToReplace.replace(mentionNode);
        }
        mentionNode.select();
        closeMenu();
      });
    },
    [editor, match, closeMenu],
  );

  useEffect(() => {
    const updateListener = () => {
      editor.getEditorState().read(() => {
        const range = document.createRange();
        const selection = $getSelection();
        const text = getQueryTextForSearch(editor);

        if (
          !$isRangeSelection(selection) ||
          !selection.isCollapsed() ||
          text === null ||
          range === null
        ) {
          closeMenu();
          return;
        }

        const _match = checkForMentionMatch(text);
        setSearchTerm(_match?.matchingString ?? null);

        if (_match === null || isSelectionOnEntityBoundary(editor, _match.leadOffset)) {
          closeMenu();
          return;
        }

        const isRangePositioned = tryToPositionRange(_match.leadOffset, range);
        if (!isRangePositioned) {
          closeMenu();
          return;
        }

        refs.setReference({
          getBoundingClientRect: () => range.getBoundingClientRect(),
          getClientRects: () => range.getClientRects(),
        });
        openMenu(_match);
      });
    };

    const insertMentionCommandListener = editor.registerCommand<MentionType>(
      INSERT_MENTION_COMMAND,
      (_mentionType) => {
        setTypeFilter(_mentionType);
        const selection = $getSelection();
        const rangeSelection = $isRangeSelection(selection) ? selection : $getRoot().selectEnd();

        const textToInsert = getMentionTriggerText(rangeSelection);
        rangeSelection.insertText(textToInsert);

        return true;
      },
      COMMAND_PRIORITY_EDITOR,
    );

    return mergeRegister(
      editor.registerUpdateListener(updateListener),
      insertMentionCommandListener,
    );
  }, [editor, searchTerm, isMenuOpen]);

  if (isMenuOpen && (mentionOptionsQuery.data || shareContactOptionsQuery.data)) {
    return (
      <div
        ref={refs.setFloating}
        style={floatingStyles}
        {...getFloatingProps()}
        className="z-50 min-w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none p-1 overflow-hidden"
      >
        <MentionsMenu
          mentionOptions={!typeFilter || typeFilter === 'mention' ? mentionOptionsQuery.data : []}
          shareOptions={!typeFilter || typeFilter === 'share' ? shareContactOptionsQuery.data : []}
          assistantOptions={!typeFilter || typeFilter === 'assistant' ? assistantChoices : []}
          onSelectOption={handleSelectOption}
        />
      </div>
    );
  }

  return null;
}
