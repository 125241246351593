import { StateCreator } from 'zustand';
import { CombinedSlices } from '../useGlobalStore';
import { ITenantDto } from '../../../features/Companies/models/ITenantDto';

// ViewMode allows us to persist if we want to see all resources
// or just the resources of a specific tenant when navigating between routes
export type ViewMode = 'specific' | 'all';

export interface TenantSlice {
  setActiveTenant: (activeTenant: ITenantDto) => void;
  activeTenant: ITenantDto;
  setTenantViewMode: (tenantViewMode: ViewMode) => void;
  tenantViewMode?: ViewMode;
}

export const createTenantSlice: StateCreator<
  CombinedSlices,
  [['zustand/persist', unknown], ['zustand/devtools', never]],
  [],
  TenantSlice
> = (set) => ({
  activeTenant: {} as ITenantDto,
  setActiveTenant: (activeTenant) => {
    set({ activeTenant });
  },
  tenantViewMode: 'specific',
  setTenantViewMode: (tenantViewMode) => {
    set({ tenantViewMode });
  },
});
