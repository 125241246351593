import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { PageTabs, TabItem } from '../../shared/components/tabs/PageTabs/PageTabs';
import ContactTabSettings from './ContactTabSettings';
import ContactCompanyView from './tabViews/ContactCompanyView';
import { listTab, ITabSetting, recommendedTab } from '../../shared/store/slices/viewSlice';
import ContactAllContactsTab from './tabs/ContactAllContactsTab';
import usePageTabs from '../../shared/components/tabs/PageTabs/hooks/usePageTabs';
import { useIsAllRoute } from '../../shared/hooks/useIsRoute';

export function ContactsList() {
  const { tenantId } = useParams();
  const { tabs, removeTab } = usePageTabs('contact');
  const isAllRoute = useIsAllRoute();

  const [currentTabs, setCurrentTabs] = useState<TabItem[]>([]);
  useEffect(() => {
    const recommendedItem: TabItem = {
      ...recommendedTab,
      isHidden: !tabs?.open.some((tabSetting: ITabSetting) => tabSetting.id === recommendedTab.id),
      content: <div>Recommended contacts</div>,
    };

    const allContactsTab: TabItem = {
      ...listTab,
      content: <ContactAllContactsTab />,
    };

    const tenantTab: TabItem = {
      ...listTab,
      id: tenantId as string,
      content: <ContactCompanyView tenantId={tenantId as string} />,
    };

    const activePrimaryTab = isAllRoute ? allContactsTab : tenantTab;
    setCurrentTabs([activePrimaryTab, recommendedItem]);
  }, [tenantId, isAllRoute, tabs]);

  const handleHideItem = (tab: TabItem) => {
    setCurrentTabs((prevTabs) => prevTabs.filter((t) => t.id !== tab.id));
    removeTab('contact', tab.id);
  };

  return (
    <PageTabs
      items={currentTabs}
      defaultTabId={currentTabs[0]?.id}
      onHideItem={handleHideItem}
      settingsComponent={<ContactTabSettings />}
    />
  );
}
