import { useGlobalStore } from '../store/useGlobalStore';

export default function useTenants() {
  const activeTenant = useGlobalStore((state) => state.activeTenant);
  const setActiveTenant = useGlobalStore((state) => state.setActiveTenant);
  const tenantViewMode = useGlobalStore((state) => state.tenantViewMode);
  const setTenantViewMode = useGlobalStore((state) => state.setTenantViewMode);

  return { activeTenant, setActiveTenant, tenantViewMode, setTenantViewMode };
}
