import { ChevronDown } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import {
  SidebarGroup,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuAction,
  SidebarMenuButton,
  SidebarMenuItem,
  useSidebar,
} from './Sidebar';

import { INavItem } from '../../../shared/model/INavItem';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuDivider,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../../../shared/components/drop-down-menu/DropdownMenu';
import AddIcon from '../../../shared/components/icons/AddIcon';
import useModal from '../../../shared/components/modal/hooks/useModal';
import { OrganizationCreateEditModal } from '../../../features/Companies/OrganizationCreateEditModal';
import { useResponsiveQueries } from '../../../shared/hooks/useResponsiveQueries';
import useTenant from '../../../shared/hooks/useTenant';
import { useTenantsQuery } from '../../../features/Companies/queries/tenantQueries';

interface INavCopmaniesProps {
  navItems: INavItem[];
}

export function NavCompanies({ navItems }: INavCopmaniesProps) {
  const { setOpenMobile } = useSidebar();
  const { openModal } = useModal();
  const { isSmallScreen, isMediumScreen } = useResponsiveQueries();
  const navigate = useNavigate();

  const { activeTenant, setActiveTenant, setTenantViewMode } = useTenant();
  const tenantsQuery = useTenantsQuery();

  const handleCreateOrganizationSuccess = (organizationId: string) => {
    navigate(`/companies/${organizationId}`);

    const newActiveTenant = tenantsQuery.data?.pages
      ?.flat()
      .find((_tenant) => _tenant.id === organizationId);

    if (newActiveTenant) {
      setActiveTenant(newActiveTenant);
    }
  };

  const openEditCreateModal = () => {
    openModal({
      title: 'Add new company',
      modalSize: 'small',
      content: <OrganizationCreateEditModal onSuccess={handleCreateOrganizationSuccess} />,
    });
  };

  const currentCompany = navItems?.find((item) => item.id === activeTenant.id) || navItems[0];

  // Helper to avoid repeating the same map code in tablet & non-tablet modes
  const renderNavItemsDropdown = () => (
    <>
      <DropdownMenuItem className="pl-1 pb-0" key="add-company">
        <button type="button" className="flex items-center gap-1" onClick={openEditCreateModal}>
          <AddIcon className="w-8 h-8" />
          Add Company
        </button>
      </DropdownMenuItem>
      <DropdownMenuDivider />
      {navItems.map((company) => (
        <DropdownMenuItem key={company.id}>
          <Link
            to={company.route}
            className="flex flex-1 items-center gap-2 "
            onClick={() => {
              const newActiveTenant = tenantsQuery.data?.pages
                ?.flat()
                .find((_tenant) => _tenant.id === company.id);

              if (newActiveTenant) {
                setActiveTenant(newActiveTenant);
                setTenantViewMode('specific');
              }

              setOpenMobile(false);
            }}
          >
            <img
              src={company.photoUrl}
              alt={company.name}
              className="w-6 h-6 rounded-full bg-gray-dark"
            />
            <span>{company.name}</span>
          </Link>
        </DropdownMenuItem>
      ))}
    </>
  );

  if (!currentCompany) {
    return <></>;
  }

  if (isMediumScreen) {
    return (
      <>
        <DropdownMenu>
          <DropdownMenuTrigger asChild className="flex flex-1 items-start justify-center ml-1">
            <Link to={currentCompany.route}>
              <img
                src={currentCompany.photoUrl}
                alt={currentCompany.name}
                className="w-6 h-6 rounded-full bg-gray-dark"
              />
            </Link>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            className="w-48"
            side={isSmallScreen ? 'bottom' : 'right'}
            align={isSmallScreen ? 'end' : 'start'}
          >
            {renderNavItemsDropdown()}
          </DropdownMenuContent>
        </DropdownMenu>
      </>
    );
  }

  return (
    <SidebarGroup className="group-data-[collapsible=icon]:hidden">
      <SidebarGroupLabel>My Companies</SidebarGroupLabel>

      <SidebarMenu>
        <SidebarMenuItem key={currentCompany.name} className="bg-blue-light rounded-md">
          <SidebarMenuButton asChild>
            <Link
              to={currentCompany.route}
              onClick={() => {
                if (isSmallScreen) setOpenMobile(false);
              }}
            >
              <img
                src={currentCompany.photoUrl}
                alt={currentCompany.name}
                className="w-6 h-6 rounded-full bg-gray-dark"
              />
              <span>{currentCompany.name}</span>
            </Link>
          </SidebarMenuButton>

          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <SidebarMenuAction showOnHover>
                <ChevronDown className="text-muted-foreground" />
                <span className="sr-only">More</span>
              </SidebarMenuAction>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              className="w-48"
              side={isSmallScreen ? 'bottom' : 'right'}
              align={isSmallScreen ? 'end' : 'start'}
            >
              {renderNavItemsDropdown()}
            </DropdownMenuContent>
          </DropdownMenu>
        </SidebarMenuItem>
      </SidebarMenu>
    </SidebarGroup>
  );
}
