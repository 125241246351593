import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useProjectsQuery } from '../../Projects/queries/projectQueries';
import { useBoardsQuery } from '../../Projects/queries/boardQueries';
import { useTenantsQuery } from '../queries/tenantQueries';
import useTenants from '../../../shared/hooks/useTenant';
import { IBreadcrumbItem } from '../../../layout/navigation/BreadcrumbNavigator/BreadcrumbItem';
import { createRoute } from '../../../layout/navigation/helpers/routingHelpers';

export default function useCreateCompanyBreadcrumbItems() {
  const { projectId, boardId } = useParams();
  const navigate = useNavigate();

  const { activeTenant, setActiveTenant } = useTenants();

  const tenants = useTenantsQuery().data?.pages.flat();
  const projects = useProjectsQuery({ tenantId: activeTenant?.id }).data;
  const boards = useBoardsQuery({ projectId: projectId || '' }).data;

  const currentProject = projects?.data.find((project) => project.id === projectId);
  const currentBoard = boards?.data.find((board) => board.id === boardId);

  const breadcrumbItems = useMemo<IBreadcrumbItem[]>(() => {
    if (!tenants) return [];

    const breadcrumbs: IBreadcrumbItem[] = [];

    if (activeTenant) {
      const tenantBreadcrumbItem: IBreadcrumbItem = {
        name: activeTenant.name,
        id: activeTenant.id,
        siblings: tenants.map((tenant) => ({
          id: tenant.id,
          name: tenant.name,
          onClick: () => {
            setActiveTenant(tenant);
            navigate(createRoute('companies', tenant.id));
          },
        })),
        onClick: () => navigate(createRoute('companies', activeTenant.id)),
      };
      breadcrumbs.push(tenantBreadcrumbItem);
    }
    if (currentProject) {
      const projectBreadcrumbItem: IBreadcrumbItem = {
        name: currentProject.name,
        id: currentProject.id,
        siblings: projects?.data.map((project) => ({
          id: project.id,
          name: project.name,
          onClick: () => navigate(`/companies/${activeTenant.id}/projects/${project.id}`),
        })),
        onClick: () => navigate(`/companies/${activeTenant.id}/projects/${currentProject?.id}`),
      };
      breadcrumbs.push(projectBreadcrumbItem);
    }

    if (currentBoard) {
      const boardBreadcrumbItem: IBreadcrumbItem = {
        name: 'Boards',
        id: currentBoard.id,
        siblings: boards?.data.map((board) => ({
          id: board.id,
          name: board.name,
          onClick: () =>
            navigate(`/companies/${activeTenant.id}/projects/${projectId}/boards/${board.id}`),
        })),
        onClick: () =>
          navigate(`/companies/${activeTenant.id}/projects/${projectId}`),
      };

      breadcrumbs.push(boardBreadcrumbItem);
    }

    return breadcrumbs;
  }, [tenants, projects, boards, activeTenant]);

  return { breadcrumbItems };
}
