import ServiceHeader, { IServiceHeaderContent } from '../building-blocks/ServiceHeader';
import { IActionItem } from '../../shared/model/IActionItem';
import FlexContainer from '../../shared/components/structure/FlexContainer';
import { IBreadcrumbItem } from '../navigation/BreadcrumbNavigator/BreadcrumbItem';

export interface IBaseLayoutProps {
  title: string;
  breadcrumbItems?: IBreadcrumbItem[];
  headerActions?: IActionItem[];
  contentAfterHeader?: IServiceHeaderContent[];
  children: React.ReactNode;
}

function ServiceWindow({ children }: { children: React.ReactNode }) {
  return (
    <FlexContainer
      direction="column"
      className="h-full w-full overflow-hidden px-3 md:px-6 mt-4 md:mt-0 pb-3"
      gap="small"
    >
      {children}
    </FlexContainer>
  );
}

function ServiceContent({ children }: { children: React.ReactNode }) {
  return <div className="flex-1 overflow-hidden">{children}</div>;
}

export function BaseLayout({
  title,
  breadcrumbItems,
  headerActions,
  contentAfterHeader,
  children,
}: IBaseLayoutProps) {
  return (
    <ServiceWindow>
      <ServiceHeader
        text={title}
        actions={headerActions}
        contentAfterHeader={contentAfterHeader}
        breadcrumbItems={breadcrumbItems}
      />
      <ServiceContent>{children}</ServiceContent>
    </ServiceWindow>
  );
}
