import { Outlet } from 'react-router';
import { BaseLayout } from '../../layout/pages/BaseLayout';
import NetworkAddNetworkButton from './NetworkAddNetworkButton';
import { useTenantsQuery } from '../Companies/queries/tenantQueries';
import { useNetworksActions } from './hooks/useNetworksActions';
import useTenants from '../../shared/hooks/useTenant';
import { useIsAllRoute } from '../../shared/hooks/useIsRoute';
import useCreateBreadcrumbItems from '../../layout/navigation/BreadcrumbNavigator/hooks/useCreateBreadcrumbItems';

export function NetworksListLayout() {
  const { breadcrumbItems } = useCreateBreadcrumbItems('networks');
  const isAllRoute = useIsAllRoute();
  const tenantQuery = useTenantsQuery();
  const actions = useNetworksActions();

  const { activeTenant } = useTenants();

  if (!tenantQuery.isSuccess) return null;

  return (
    <BaseLayout
      title={isAllRoute ? 'All Networks' : activeTenant?.name}
      headerActions={actions}
      breadcrumbItems={breadcrumbItems}
      contentAfterHeader={[
        { id: 'addNetworkButton', content: <NetworkAddNetworkButton /> },
      ]}
    >
      <Outlet />
    </BaseLayout>
  );
}
