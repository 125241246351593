import { useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useParams } from 'react-router';
import { UnsavedDataAlertDialog } from '../../shared/components/alert-dialog/UnsavedDataAlertDialog';
import { ICreateBoardDto, BoardType } from './models/IBoardDto';
import { useCreateBoardMutation } from './queries/boardQueries';
import { BoardCreateForm, useBoardCreateForm } from './BoardCreateForm';
import { useCreatePipelineMutation } from '../Pipelines/queries/pipelineQueries';
import useTenant from '../../shared/hooks/useTenant';

interface IProps {
  projectId: string;
  onSuccess?: (projectId: string, boardId: string) => void;
  onCancel: () => void;
}

export default function BoardCreateModal({ projectId, onSuccess, onCancel }: IProps) {
  const [openUnsavedDataWarning, setOpenUnsavedDataWarning] = useState(false);
  const form = useBoardCreateForm();

  const { activeTenant } = useTenant();

  const createBoard = useCreateBoardMutation();
  const createPipeline = useCreatePipelineMutation();
  const {
    reset,
    formState: { isDirty },
  } = form;

  const handleSubmit: SubmitHandler<ICreateBoardDto> = (boardToSubmit) => {
    if (!activeTenant) return;

    const updatedBoardToSubmit: ICreateBoardDto = {
      ...boardToSubmit,
      tenantId: activeTenant.id,
    };

    createBoard.mutate(
      { projectId, board: updatedBoardToSubmit },
      {
        onSuccess: (_board) => {
          if (_board.type === BoardType.Pipeline) {
            createPipeline.mutate(
              {
                pipeline: {
                  tenantId: activeTenant.id,
                  projectId,
                  boardId: _board.id,
                  name: _board.name,
                  currencyIsoCode: boardToSubmit.resourcePayload.currencyIsoCode,
                },
              },
              {
                onSuccess: () => {
                  if (onSuccess) {
                    onSuccess(_board.id, _board.projectId);
                  }
                },
              },
            );
          }
        },
      },
    );
  };

  const handleClose = () => {
    reset();
    onCancel();
  };

  const handleCancel = (event: React.BaseSyntheticEvent) => {
    if (isDirty) {
      event.preventDefault();
      setOpenUnsavedDataWarning(true);
    } else {
      handleClose();
    }
  };

  return (
    <>
      <BoardCreateForm
        form={form}
        isDisabled={createBoard.isPending}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
      <UnsavedDataAlertDialog
        open={openUnsavedDataWarning}
        onOpenChange={setOpenUnsavedDataWarning}
        onLeaveClick={handleClose}
      />
    </>
  );
}
