import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { PageTabs, TabItem } from '../../shared/components/tabs/PageTabs/PageTabs';
import { useGlobalStore } from '../../shared/store/useGlobalStore';
import { ITabSetting, recommendedTab } from '../../shared/store/slices/viewSlice';
import NetworkAllNetworksTab from './tabs/NetworkAllNetworksTab';
import NetworkCompanyView from './tabs/views/NetworkCompanyView';
import usePageTabs from '../../shared/components/tabs/PageTabs/hooks/usePageTabs';
import NetworkTabSettings from './NetworkTabSettings';
import { useIsAllRoute } from '../../shared/hooks/useIsRoute';

export function NetworksList() {
  const { tenantId } = useParams();

  const { tabs } = usePageTabs('network');
  const isAllRoute = useIsAllRoute();

  const removeTab = useGlobalStore((state) => state.removeTab);

  const [currentTabs, setCurrentTabs] = useState<TabItem[]>([]);
  useEffect(() => {
    const recommendedItem: TabItem = {
      ...recommendedTab,
      isHidden: !tabs?.open.some((tabSetting: ITabSetting) => tabSetting.id === recommendedTab.id),
      content: <div>Recommended networks</div>,
    };

    const allNetworksTab: TabItem = {
      id: 'networks',
      name: 'All networks',
      type: 'primary',
      content: <NetworkAllNetworksTab />,
    };

    const tenantTab: TabItem = {
      id: tenantId as string,
      type: 'primary',
      name: 'Networks',
      content: <NetworkCompanyView tenantId={tenantId as string} />,
    };

    const activePrimaryTab = isAllRoute ? allNetworksTab : tenantTab;
    setCurrentTabs([activePrimaryTab, recommendedItem]);
  }, [tenantId, isAllRoute, tabs]);

  const handleHideItem = (tab: TabItem) => {
    setCurrentTabs((prevTabs) => prevTabs.filter((t) => t.id !== tab.id));
    removeTab('network', tab.id);
  };

  return (
    <PageTabs
      items={currentTabs}
      defaultTabId={currentTabs[0]?.id}
      onHideItem={handleHideItem}
      settingsComponent={<NetworkTabSettings />}
    />
  );
}
